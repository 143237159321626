const baseRequiredMessage = "*Campo obrigatório";
// const baseRequiredFieldsDescription = "* Preenchimento obrigatório";

const ptBr = {
  notification: {
    back: "VOLTAR",
    complete: "COMPLETAR CADASTRO",
    title: "FINALIZE SEU CADASTRO!",
    firstTitle: "BOAS VINDAS À PLATAFORMA",
    ok: "COMPLETAR CADASTRO",
    checkbox: "Não mostrar esta mensagem novamente",
  },
  months: [
    { initials: "Jan", fullName: "Janeiro" },
    { initials: "Fev", fullName: "Fevereiro" },
    { initials: "Mar", fullName: "Março" },
    { initials: "Abr", fullName: "Abril" },
    { initials: "Mai", fullName: "Maio" },
    { initials: "Jun", fullName: "Junho" },
    { initials: "Jul", fullName: "Julho" },
    { initials: "Ago", fullName: "Agosto" },
    { initials: "Set", fullName: "Setembro" },
    { initials: "Out", fullName: "Outubro" },
    { initials: "Nov", fullName: "Novembro" },
    { initials: "Dez", fullName: "Dezembro" },
  ],
  version: "AMCRED ",
  modals: {
    success: {
      buttonLabel: "OK",
    },
    errors: {
      buttonLabel: "Ok",
    },
  },
  login: {
    title: "Login",
    admin: "ADMINISTRADOR",
    subtitle: "Por favor, preencha os seguintes campos para entrar:",
    remember: "Lembrar-me",
    forgotPass: "Esqueci minha senha",
    keepData: "LEMBRAR DADOS",
    enter: "Entrar",
    continue: "Continuar",
    patient: "PACIENTE",
    worker: "PROFISSIONAL",
    firstAccess: "Solicitar Acesso",
    email: "E-MAIL",
    password: "SENHA",
    confirmPassword: "Confirmar Senha",

    passwordMessage: baseRequiredMessage,
    user: "CPF*",
    userMessage: baseRequiredMessage,
    stepTwo: {
      cpf: "CPF",
      cnpj: "CNPJ",
      email: "Email",
      name: "Nome do usuário",
      companyName: "Nome da empresa",
      city: "Cidade",
      uf: "UF",
      phone: "Telefone (Opcional)",
      cnae: "CNAE",
    },
    modal: {
      title: "Termos",
      subtitle: "Leia atentamente antes de continuar:",

      agree: "Li e Concordo com os Termos de Uso",
      confirm: "CONFIRMAR",
    },
  },
  registry: {
    title: "Solicitar Acesso",
    admin: "ADMINISTRADOR",
    subtitle:
      "Para realizar a solicitação de acesso, por favor informe os dados abaixo:",
    remember: "Lembrar-me",
    forgotPass: "Esqueci minha senha",
    keepData: "LEMBRAR DADOS",
    enter: "CONFIRMAR SOLICITAÇÃO",
    continue: "Continuar",
    patient: "PACIENTE",
    worker: "PROFISSIONAL",
    firstAccess: "CANCELAR",
    email: "E-MAIL",
    companyName: "NOME DA INSTITUIÇÃO ASSOCIADA",
    cnpj: "CNPJ",
    name: "NOME GESTOR",
    lastName: "SOBRENOME GESTOR",
    password: "SENHA",
    confirmPassword: "Confirmar Senha",
    profile: "PERFIL",
    passwordMessage: baseRequiredMessage,
    user: "CPF",
    userMessage: baseRequiredMessage,
    stepTwo: {
      cpf: "CPF",
      cnpj: "CNPJ",
      email: "Email",
      name: "Nome do usuário",
      companyName: "Nome da empresa",
      city: "Cidade",
      uf: "UF",
      phone: "Telefone (Opcional)",
      cnae: "CNAE",
    },
    modal: {
      title: "Termos",
      subtitle: "Leia atentamente antes de continuar:",

      agree: "Li e Concordo com os Termos de Uso",
      confirm: "CONFIRMAR",
    },
  },
  forgotPassword: {
    copyright: " ASSOCIAÇÃO CATARINENSE DE TECNOLOGIA, 2022",
    Title: "Esqueci minha senha",
    emailConfirmatiom: {
      text: "Para continuar, insira seu email. Você receberá um código de confirmação para definir sua nova senha:",
      subText:
        "Você receberá um código de confirmação para definir sua nova senha.",
      confirm: "Confirmar",
      back: "Voltar",
      email: "E-MAIL",
      successResponse: "Senha alterada com sucesso.",
      inputEmailRequiredMessage: baseRequiredMessage,
    },

    tokenConfirmatiom: {
      textToken:
        "Para redefinir sua senha, confirme o Token de 5 dígitos enviado para o seu email:",
      instertToken: "CONFIRMAR TOKEN",
      confirmToken: "CONFIRMAR",
      resendToken: "Reenviar Token",
      back: "Voltar",
    },

    passwordConfirmatiom: {
      textNewPassword:
        "Digite abaixo uma nova senha que gostaria de usar para sua conta:",
      newPassword: "Nova Senha",
      confirmNewPassword: "Confirmação de Nova Senha",
      inputPasswordRequiredMessage: "*As senhas não conferem",
      confirm: "CONFIRMAR NOVA SENHA",
      back: "CANCELAR RECUPERAÇÃO DE SENHA",
      modalTitle: "Senha Redefinida com Sucesso!",
      modalText:
        "A sua nova senha foi criada com sucesso e você já pode começar a utilizá-la nesta conta.",
      buttomModal: "CONTINUAR PARA LOGIN",
    },
    modalCanel: {
      modalTitle: "CANCELAR RECUPERAÇÃO",
      modalText:
        "Ao cancelar o processo de recuperação de senha, a mesma não será redefinida e continuará como a antiga.",
      buttomModal: "SIM, CANCELAR",
      cancel: "VOLTAR",
    },
  },

  error: {
    backHome: "VOLTAR PARA HOME",
    reload: "RECARREGAR",
    error404: "Erro 404.",
    message404: " Esta página apresenta estar fora do ar.",
  },

  header: {
    roles: {
      admin: "ADMINISTRADOR",
      amcred: "AMCRED",
      institution: "INSTITUTION",
    },
    settings: "Configurações",
    system: "Sistema",
    settingsOptions: {
      integration: "Integrações",
      users: "Usuários",
      permissions: "Permissões",
      changePassword: "Alterar Senha",
    },
    exit: "Deseja encerra sua sessão?",
    exitConfirmationText:
      "Deseja realmente encerra sua sessão e sair do sistema?",
    exitConfirmation: "Sair",
    exitDeny: "Cancelar",
  },

  Menu: {
    system: "SISTEMA",
    general: "GERAL",
  },

  leave: {
    title: "Sair",
    logoutModal: {
      reportText: "Identifique o tipo de problema ou erro que deseja relatar:",
      exitTitle: "SAIR DO SISTEMA",
      reportTitle: "RELATAR UM PROBLEMA",
      exitConfirmationText: "Encerrar sessão atual e sair do sistema?",
      exitConfirmation: "Sim, sair",
      back: "VOLTAR",
    },
  },

  Profile: {
    title: "PERFIL",
    confirm: "Salvar Alterações",
    validationField: "*Campo Obrigatório",
    newPassword: {
      passwordMatch: "As senhas não correspondem.",
      passwordChange: "Alterar Senha",
      password: "Senha Atual",
      newPassword: "Nova Senha",
      confirmPassword: "CONFIRMAR NOVA SENHA",
      forgotPassword: "Esqueci minha senha",
    },
    basicData: {
      myBasicData: "MEUS DADOS",
      companyName: "NOME DA EMPRESA",
      cpf: "CPF",
      fullName: "NOME COMPLETO",
      email: "E-MAIL",
    },
  },
  RegisterAndQuery: {
    title: "Cadastro e Consulta",
  },

  GeneralReport: {
    title: "Relatório Geral",
    searchReport: {
      title: "PESQUISAR RELATÓRIOS GERAIS",
      institution: "NOME DA INSTITUIÇÃO ASSOCIADA",
      username: "NOME DO USUÁRIO",
      cnpj: "CNPJ",
      role: "PERFIL",
      period: "PERÍODO",
    },
    header: {
      title: "LISTAGEM DE RELATÓRIOS GERAIS",
      button: "Exportar",
      total: "TOTAL A PAGAR:  ",
    },
  },

  DetailReport: {
    title: "Relatório Detalhado",
    searchReport: {
      title: "PESQUISAR RELATÓRIOS DETALHADOS",
      institution: "NOME DA INSTITUIÇÃO ASSOCIADA",
      username: "NOME DO USUÁRIO",
      cnpj: "CNPJ",
      role: "PERFIL",
      period: "PERÍODO",
    },
    header: {
      title: "LISTAGEM DE RELATÓRIOS DETALHADOS",
      button: "Exportar",
      total: "TOTAL A PAGAR:  ",
    },
  },

  CreditAnalysis: {
    title: "ANÁLISE DE CRÉDITO",
    confirm: "Confirmar",
    validationField: "*Campo Obrigatório",

    creditForm: {
      title: "FORMULÁRIO DE PREENCHIMENTO",
      send: "HÁ POSSIBILIDADE DE LIBERAR CRÉDITO?",
      search: "REALIZAR ANÁLISE E OBTER SCORE (CHANCE DE NÃO PAGAR)",
      type: "TIPO DE CADASTRO",
      cpf: "CPF",
      cnpj: "CNPJ",
      birthday: "DATA DE NASCIMENTO",
      birthdayCnpj: "DATA DE ABERTURA DA EMPRESA",
      companyDate: "DATA DE ABERTURA DA EMPRESA",
      spc: "CONCEITO SPC",
      school: "FORMAÇÃO ESCOLAR",
      schoolCnpj: "FORMAÇÃO ESCOLAR SÓCIO",
      residence: "SITUAÇÃO DO IMÓVEL DE RESIDÊNCIA",
      numPeople: "NÚMERO DE PESSOAS NA RESIDÊNCIA",
      familiarWealth: "RENDA FAMILIAR MENSAL",
      totalReceipt: "TOTAL DE RECEITAS MENSAL",
      constitution: "CONSTITUIÇÃO",
      residenceSituation: "SITUAÇÃO DO IMÓVEL DE ATIVIDADE",
      point: "TIPO DE PONTO",
      numberWorkers: "NÚMERO DE FUNCIONÁRIOS",
      loanAmount: "VALOR EMPRÉSTIMO",
      monthlyFee: "TAXA MENSAL",
      numbInstallments: "QNTD. PARCELAS",
      loanFinality: "FINALIDADE DO EMPRÉSTIMO",
      clean: "Limpar",
      cep: "CEP RESIDENCIAL",
      cepCnpj: "CEP COMERCIAL",
    },
  },
};

export default ptBr;
