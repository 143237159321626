import React from "react";
import { CustomText } from "../../../components";
import Styles from "../styles/Styles";

import { Spacing, Texts } from "../../../config";

import { Info } from "@material-ui/icons";
import fonts from "../../../config/fonts";
function AnalysisScore({ scoreValue, isFetching }) {
  const texts = Texts["pt-BR"].CreditAnalysis;
  const scoreLabel = (value) => {
    if (!value) {
      return "-";
    }

    if (value <= parseFloat(process.env.REACT_APP_SCORE_A)) {
      return "A - Muito Baixa";
    } else if (
      value > parseFloat(process.env.REACT_APP_SCORE_A) &&
      value <= parseFloat(process.env.REACT_APP_SCORE_B)
    ) {
      return "B - Baixa";
    } else if (
      value > parseFloat(process.env.REACT_APP_SCORE_B) &&
      value <= parseFloat(process.env.REACT_APP_SCORE_C)
    ) {
      return "C - Regular";
    } else if (
      value > parseFloat(process.env.REACT_APP_SCORE_C) &&
      value <= parseFloat(process.env.REACT_APP_SCORE_D)
    ) {
      return "D - Alta";
    } else if (value > parseFloat(process.env.REACT_APP_SCORE_D)) {
      return "E - Muito Alta";
    }
  };

  console.log(scoreValue);
  return (
    <Styles.CardContainer fullHeight>
      <Styles.CardHeader>
        <Styles.CardTitle>{texts.creditForm.search}</Styles.CardTitle>
      </Styles.CardHeader>
      <Styles.Line />
      <Styles.ScoreFormContainer>
        <Info
          style={{
            height: Spacing(1.75),
            width: Spacing(1.75),
            marginTop: Spacing(0.5),
            marginRight: Spacing(0.5),
            opacity: 0.66,
          }}
        />
        <div>
          <CustomText
            fontSize={1.75}
            fontFamily={fonts.medium}
            styles={{ opacity: 0.66 }}
          >
            O botão "Realizar Análise" será habilitado automaticamente após a
            validação do CPF / CNPJ.
          </CustomText>
          <CustomText
            fontSize={1.75}
            fontFamily={fonts.medium}
            styles={{ opacity: 0.66, marginTop: Spacing(0.25) }}
          >
            Ao efetuar a ação de realizar a análise, a taxa de cobrança padrão
            de seu plano será aplicada automaticamente na fatura vigente no nome
            de sua Instituição Associada
          </CustomText>
        </div>

        <Styles.ButtonSubmit
          color={1}
          disabled={Boolean(scoreValue) || isFetching}
          loading={isFetching}
          fullWidth={false}
          type="submit"
        >
          Realizar Análise
        </Styles.ButtonSubmit>
      </Styles.ScoreFormContainer>
      <Styles.ScoreContainer>
        <CustomText
          fontSize={3.5}
          fontFamily={fonts.bold}
          styles={{ opacity: Boolean(scoreValue) ? 1 : 0.33 }}
        >
          {scoreValue ? scoreLabel(scoreValue) : "--"}
        </CustomText>
      </Styles.ScoreContainer>
    </Styles.CardContainer>
  );
}

export default React.memo(AnalysisScore);
