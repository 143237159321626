import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { CustomText, Pagination, Table } from "../../../components";
import { Fonts, Spacing, SVG } from "../../../config";
import ButtonMenuComponent from "../../../components/buttons/ButtonMenu";
import { ArrowDropDown, Edit } from "@material-ui/icons";

function TableUsers({
  isFetching,
  list,
  page,
  setPage,
  totalPages,
  onExport,
  onEdit,
  onEditAll,
}) {
  const scoreLabel = (value) => {
    if (!value) {
      return "-";
    }

    if (value <= parseFloat(process.env.REACT_APP_SCORE_A)) {
      return "A - Muito Baixa";
    } else if (
      value > parseFloat(process.env.REACT_APP_SCORE_A) &&
      value <= parseFloat(process.env.REACT_APP_SCORE_B)
    ) {
      return "B - Baixa";
    } else if (
      value > parseFloat(process.env.REACT_APP_SCORE_B) &&
      value <= parseFloat(process.env.REACT_APP_SCORE_C)
    ) {
      return "C - Regular";
    } else if (
      value > parseFloat(process.env.REACT_APP_SCORE_C) &&
      value <= parseFloat(process.env.REACT_APP_SCORE_D)
    ) {
      return "D - Alta";
    } else if (value > parseFloat(process.env.REACT_APP_SCORE_D)) {
      return "E - Muito Alta";
    }
  };

  const formatDate = (date) => {
    var data = new Date(date);

    var dia = data.getDate();
    var mes = data.getMonth() + 1;
    var ano = data.getFullYear();
    var horas = data.getHours();
    var minutos = data.getMinutes();

    dia = dia < 10 ? "0" + dia : dia;
    mes = mes < 10 ? "0" + mes : mes;
    horas = horas < 10 ? "0" + horas : horas;
    minutos = minutos < 10 ? "0" + minutos : minutos;

    var dataString =
      dia + "/" + mes + "/" + ano + " - " + horas + ":" + minutos;
    return dataString;
  };
  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          LISTAGEM DE HISTÓRICO DE CONSULTAS
        </CustomText>
        <div style={{ display: "flex", flexDirection: "row", gap: Spacing(1) }}>
          <Styles.EditAll
            startIcon={<Edit />}
            fullWidth={false}
            onClick={() => onEditAll()}
          >
            Editar Lote
          </Styles.EditAll>
          <Styles.Export
            startIcon={<SVG.Export />}
            fullWidth={false}
            onClick={onExport}
          >
            Exportar
          </Styles.Export>
        </div>
      </Styles.HeaderCard>
      <Styles.Line></Styles.Line>
      <Table
        id={"teste"}
        color={1}
        headers={{
          table: [
            "CLIENTE",
            "SCORE",
            "DATA / HORA",
            "LIBERAÇÃO DE CRÉDITO",
            "AÇÃO",
          ],
        }}
        data={list}
        renderItemColumns={(item) => [
          item?.cpfOrCnpj,
          item?.score ? scoreLabel(item?.score) : "-",
          formatDate(item?.createdAt),
          item?.signalCreditRelease,
          <div
            style={{
              gap: Spacing(1),
              display: "flex",

              justifyContent: "flex-start",
            }}
          >
            {(item?.signalCreditRelease === null ||
              item?.signalCreditRelease === "Indefinida") && (
              <ButtonMenuComponent
                color={1}
                endIcon={<ArrowDropDown />}
                buttons={[
                  {
                    label: "Editar",
                    icon: Edit,
                    onClick: () => onEdit(item),
                  },
                ]}
              >
                Opções
              </ButtonMenuComponent>
            )}
          </div>,
        ]}
      />
      <Pagination
        color={1}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TableUsers));
